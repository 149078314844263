<template>
  <StaticPagePug>
    <div id="resetPassword">
      <div class="form-wrapper">
        <div class="inner">
          <h2>{{ $t('resetPassword.header') }}</h2>
          <div class="resetPassword-box" v-show="isAuthorized">
            <div class="form-box" v-show="formFlag">
              <el-form
                label-position="top"
                :model="ruleResetPasswordForm"
                :rules="rulesResetPassword"
                ref="ruleResetPasswordForm"
              >
                <el-form-item :label="$t('common.field.newPw')" prop="pass">
                  <el-input type="password" v-model="ruleResetPasswordForm.pass" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.field.confirmPw')" prop="checkPass">
                  <el-input type="password" v-model="ruleResetPasswordForm.checkPass" autocomplete="off"></el-input>
                </el-form-item>
                <p :class="{ isError: isError }">
                  {{ $t('common.formValidation.newPwFormat') }}
                </p>
                <el-form-item class="btn-item">
                  <el-button
                    class="btn-blue"
                    type="primary"
                    @click.native="submitForm('ruleResetPasswordForm')"
                    data-testid="submit"
                  >
                    {{ $t('common.button.submit') }}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
            <div class="reset-success" v-show="successFlag">
              <img src="@/assets/images/dialog_success.png" alt />
              <p class="success_info">{{ $t('resetPassword.succ') }}</p>
              <div class="btn-box">
                <router-link :to="{ name: 'home' }" class="el-button btn-blue">
                  {{ $t('common.button.bkToHm') }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="resetPassword-box" v-show="isNotAuthroized">
            <p class="no-authroization">{{ $t('resetPassword.failed') }}</p>
          </div>
        </div>
      </div>
    </div>
  </StaticPagePug>
</template>

<script>
import StaticPagePug from '@/components/template/staticPage/StaticPagePug';
import { rsa } from '@/util/encrypt';
import passwordValidator from 'password-validator';
import { apiCheckResetPasswordAuthorization, apiSubmitResetMt4AccountPassword } from '@/resource';
import passwordCheckMixin from '@/mixins/passwordCheck';

export default {
  name: 'resetPassword',
  mixins: [passwordCheckMixin],
  data() {
    return {
      isAuthorized: false,
      isNotAuthroized: true,
      formFlag: true,
      successFlag: false,
      isError: false,
      ruleResetPasswordForm: {
        pass: '',
        checkPass: '',
        token: ''
      },
      schema: ''
    };
  },
  components: { StaticPagePug },
  mounted() {
    const token = this.$route.query.token;
    this.schema = new passwordValidator();
    this.schema
      .is()
      .min(8)
      .has()
      .uppercase()
      .has()
      .digits()
      .has()
      .not()
      .spaces();
    this.verifyAuthorization(token);
  },
  methods: {
    verifyAuthorization(token) {
      apiCheckResetPasswordAuthorization({
        token: token
      }).then(resp => {
        if (resp.data.code == 0) {
          this.ruleResetPasswordForm.token = resp.data.data;
          this.isAuthorized = true;
          this.isNotAuthroized = !this.isAuthorized;
        } else {
          this.isAuthorized = false;
          this.$router.push('/login');
        }
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          apiSubmitResetMt4AccountPassword({
            token: this.ruleResetPasswordForm.token,
            newPassword: rsa(this.ruleResetPasswordForm.pass)
          })
            .then(resp => {
              if (resp.data.code == 0 && resp.data.data == true) {
                this.formFlag = false;
                this.successFlag = true;
              }
            })
            .catch(err => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/resetProfilePasswordPug.scss';
</style>
